@import url("https://fonts.googleapis.com/css2?family=Changa:wght@400;500;700&display=swap");

html {
  scroll-behavior: smooth;
}

.home-page {
  display: flex;
  position: relative;
  width: 100%;
  height: fit-content;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: rgb(219, 232, 196);

  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  font-size: 20px;

  a {
    color: inherit;
  }

  .travel-reimbursement-banner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: orange;
    padding: 8px 0; /* Reducido de 15px a 8px */
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-weight: 700;
  }

  .banner-text {
    margin: 0;
    text-align: center;
    font-size: 1em; /* Reducido de 1.2em a 1em */
    color: black;
    font-weight: bold;
  }
}

/* Adjust styles when viewport height is less than or equal to 800px */
@media (max-height: 800px) {
  .hero-major {
    font-size: 2.5em;
  }
  .hero-minor,
  .hero-date,
  .hero-presented {
    font-size: 1.3em;
  }
  .hero-logo {
    max-height: 450px;
  }
  .hero-flex {
    padding: 1.5em 0;
  }
} /* Adjust styles when viewport height is less than or equal to 600px */
@media (max-height: 600px) {
  .hero-major {
    font-size: 2em;
  }
  .hero-minor,
  .hero-date,
  .hero-presented {
    font-size: 1.1em;
  }
  .hero-logo {
    max-height: 350px;
  }
  .hero-flex {
    flex-direction: column; /* Stack elements vertically */
    padding: 1em 0;
  }
  .flex-left,
  .flex-right {
    width: 100%;
  }
} /* Adjust styles when viewport height is less than or equal to 400px */
@media (max-height: 400px) {
  .hero-major {
    font-size: 1.5em;
  }
  .hero-minor,
  .hero-date,
  .hero-presented {
    font-size: 0.9em;
  }
  .hero-logo {
    max-height: 250px;
  }
  .hero-flex {
    padding: 0.5em 0;
  }
  .hero-button {
    padding: 0.5em 1em;
    font-size: 0.9em;
  }
}

.continue-reading {
  display: flex;
  position: relative;
  height: fit-content;
  width: fit-content;
  flex-direction: column;
  margin: 0 auto;
  float: right;

  justify-content: flex-start;
  align-items: center;

  animation: hover 5s infinite;

  text-decoration: none;
  line-height: 1em;

  &:hover {
    animation-play-state: paused;
    > .text {
      text-decoration: underline;
    }
  }

  > .text {
    display: block;
    position: relative;
    width: 100%;
    height: auto;
  }
}

.mlh-section {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 5em;
  width: 100%;
  margin: 0 auto;

  z-index: 6;
  pointer-events: none;

  > .mlh-wrapper {
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding-left: 40px;

    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;

    > .mlh-badge {
      display: block;
      position: relative;
      width: 100px;
      height: auto;
      pointer-events: auto;

      > span {
        width: 100%;
        height: auto;

        > img {
          display: block;
          position: relative;
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .mlh-section {
    > .mlh-wrapper {
      padding-left: 1%; /* Move the banner further to the left on mobile */
    }
  }
}

#hero {
  padding-top: 2.25em;
  a {
    color: inherit;
    text-decoration: none;

    cursor: pointer;
    overflow: hidden;
    overflow-y: hidden;
  }
  .hero-flex {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0px 50px;
    padding-top: 2em;

    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    > .flex-left {
      display: flex;
      position: relative;
      height: calc(100vh - 5em);
      width: 50%;
      // float: left;

      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      color: #020202;

      // border: 1px solid red;

      > .hero-major {
        display: block;
        position: relative;
        height: fit-content;
        width: fit-content;
        text-align: left;
        font-size: 5em;
        font-weight: 700;
        line-height: 0.9em;
        font-family: "Changa", sans-serif; /* Apply Changa font */

        margin-top: 0;
        margin-bottom: 0.1em; /* Add this to control the space below */
        margin-left: 1.2em;
      }
      > .hero-minor {
        display: block;
        position: relative;
        height: fit-content;
        width: fit-content;
        text-align: left;
        font-size: 3em;
        font-weight: 500;
        font-family: "Changa", sans-serif; /* Apply Changa font */
        line-height: 0.9em;
        color: rgba(0, 0, 0, 0.8);
        margin-top: 0;
        margin-bottom: 0.1em;
        margin-left: 2em;
      }
      > .hero-presented {
        display: block;
        position: relative;
        height: fit-content;
        width: fit-content;
        text-align: left;
        font-size: 3em;
        font-weight: 500;
        font-family: "Changa", sans-serif; /* Apply Changa font */
        line-height: 0.9em;
        color: rgba(0, 0, 0, 0.8);
        margin-top: 0.1em;
        margin-bottom: 0.1em;
        margin-left: 2em;
      }
      > .hero-date {
        display: block;
        position: relative;
        height: fit-content;
        width: fit-content;
        text-align: left;
        font-size: 2em;
        font-weight: 500;
        font-family: "Changa", sans-serif;
        line-height: 0.9em;
        color: rgba(0, 0, 0, 0.8);
        margin-top: 0;
        margin-bottom: 0.1em;
        margin-left: 3em;
      }
      > .hero-date-mini {
        display: block;
        position: relative;
        height: fit-content;
        width: fit-content;
        text-align: left;
        font-size: 1.5em;
        font-weight: 500;
        font-family: "Changa", sans-serif;
        line-height: 0.9em;
        color: rgba(255, 255, 255, 0.7);
        margin-top: 0;
        margin-bottom: 0.5em;
      }
      > .hero-button {
        display: flex; /* Use Flexbox */
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically */
        position: relative;
        height: fit-content;
        width: fit-content;

        font-size: 1.6em;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.8);
        background-color: white; /* Default background color is white */

        padding: 5px 30px 10px 30px;
        border: 6px solid rgba(0, 0, 0, 0.8);
        border-radius: 100px;
        transition: 0.3s all ease;

        &:hover {
          background: rgba(
            235,
            144,
            31,
            0.5
          ); /* Change background color on hover */
          border: 6px solid rgba(235, 144, 31, 0.5);
          text-shadow: 0px 0px 14px rgba(255, 255, 255, 0.5);
        }

        &:active {
          filter: brightness(0.8);
        }

        margin-top: 0.6em;
        margin-bottom: 0.6em;
        margin-left: 3.7em;
      }
    }
    > .flex-right {
      display: flex;
      position: relative;
      height: calc(100vh - 5em);
      width: 50%;
      // float: right;

      flex-direction: column;
      justify-content: center;
      align-items: center;

      color: #fff;
      > .logo-stars {
        display: block;
        position: relative;
        width: 80%;
        height: fit-content;
        > .hero-logo {
          display: block;
          position: relative;
          height: auto;
          width: 100%;
          // width: 80%;

          filter: drop-shadow(0px 0px 100px #f3ae4a);
        }
        > .star {
          display: block;
          position: absolute;
          width: 40px;
          transition: 0.3s all ease;
          filter: drop-shadow(0px 0px 50px #f3ae4a);
        }
        > .star-a {
          width: 40px;
          top: -20px;
          right: 40px;
          transform: rotate(15deg);
          animation: StarRotateA 5s infinite;
        }
        > .star-b {
          width: 70px;
          top: -40px;
          right: -40px;
          transform: rotate(15deg);
          animation: StarRotateA 5s infinite;
        }
        > .star-c {
          width: 40px;
          bottom: -10px;
          left: 40px;
          transform: rotate(-15deg);
          animation: StarRotateB 5s infinite;
        }
        > .star-d {
          width: 80px;
          bottom: 0px;
          left: -40px;
          transform: rotate(-15deg);
          animation: StarRotateB 5s infinite;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .hero-flex {
      padding-top: 5em; /* Increase top padding for screens 1200px or smaller */
    }
  }

  @media (max-width: 768px) {
    .hero-flex {
      padding-top: 7em; /* Increase top padding for screens 768px or smaller */
    }
  }

  @media (max-width: 480px) {
    .hero-flex {
      padding-top: 10em; /* Increase top padding for screens 480px or smaller */
    }
  }
}

@keyframes StarRotateA {
  0% {
    transform: rotate(15deg);
  }
  // 50% {
  //     transform: rotate(15deg);
  // }
  100% {
    transform: rotate(15deg);
  }
}
@keyframes StarRotateB {
  0% {
    transform: rotate(-15deg);
  }
  // 50% {
  //     transform: rotate(-15deg);
  // }
  100% {
    transform: rotate(-15deg);
  }
}
// @keyframes StarRotateA {
//     0% {
//         transform: rotate(15deg);
//     }
//     50% {
//         transform: rotate(-15deg);
//     }
//     100% {
//         transform: rotate(15deg);
//     }
// }
// @keyframes StarRotateB {
//     0% {
//         transform: rotate(-15deg);
//     }
//     50% {
//         transform: rotate(15deg);
//     }
//     100% {
//         transform: rotate(-15deg);
//     }
// }

@keyframes hover {
  0% {
    transform: translateY(45px);
  }

  50% {
    transform: translateY(55px);
  }
  100% {
    transform: translateY(45px);
  }
}

@keyframes hoverhero {
  0% {
    transform: translateY(-45px);
  }

  50% {
    transform: translateY(-55px);
  }
  100% {
    transform: translateY(-45px);
  }
}

#about {
  position: relative;
  padding: 0;
  background: rgb(219, 232, 196);
}

.about-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 50px 0; /* Adjusted the padding */
}

.wood-banner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -4em; /* Adjusted top position to lower the banner */
}

.wood-banner-about {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -4em; /* Adjusted top position to lower the banner */
  width: 98%;
  max-width: 1000px;
}

.banner-img {
  width: 100%;
  max-width: 600px;
}

.about-title {
  position: absolute;
  font-size: 3em;
  font-weight: 700;
  font-family: "Changa", sans-serif;
  color: #000;
  bottom: 2.25em;
}

.about-content {
  position: relative;
  width: 100%;
  margin-top: -20em; /* Shift up the about-content */
}

.text-diagonal {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, auto);
  gap: 10px 20px;
  width: 100%;
}

.about-text {
  font-size: 1.2em;
  font-family: "Changa", sans-serif;
  line-height: 1.5em;
  max-width: 350px; /* Constrain the width of each text block */
  margin: 10px;
}

.about-text:nth-child(1) {
  grid-column: 1 / span 1;
  grid-row: 1;
  justify-self: start;
}

.about-text:nth-child(2) {
  grid-column: 2 / span 1;
  grid-row: 2;
  justify-self: start;
  margin-top: -20px; /* Adjust to make the slope less steep */
}

.about-text:nth-child(3) {
  grid-column: 3 / span 1;
  grid-row: 3;
  justify-self: start;
  margin-top: -20px; /* Adjust to make the slope less steep */
}

.about-text:nth-child(4) {
  grid-column: 4 / span 1;
  grid-row: 4;
  justify-self: start;
  margin-top: -20px; /* Adjust to make the slope less steep */
}

.fish-fossil {
  position: absolute;
  top: 35%; /* Adjusted to shift up the fish fossil */
  right: 0;
  width: 15em;
  height: auto;
  transform: translateY(-50%);
  animation: rotate-slightly 3s infinite alternate ease-in-out; /* Add animation */
}

@keyframes rotate-slightly {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(10deg);
  }
}

@media (max-width: 768px) {
  .text-diagonal {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .about-content {
    margin-top: 0; /* Reset margin-top on mobile */
  }

  .footprints-animation {
    display: none; /* Hide footprints on mobile */
  }

  .fish-fossil {
    position: static; /* Change position to static on mobile */
    margin-top: 5em; /* Add some margin to separate from the text */
    transform: none; /* Remove transform on mobile */
    animation: rotate-slightly 3s infinite alternate ease-in-out; /* Add animation */
  }

  .wood-banner-about {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 2em; /* Adjusted top position to lower the banner */
    width: 70%;
    max-width: 500px;
    height: auto;
  }
}

#whatis,
#whatis2 {
  margin: 100px 0;

  .whatis-flex {
    display: flex;
    position: relative;
    width: 80%;
    padding: 100px;
    margin: 0 auto;
    height: fit-content;
    color: #000;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; /* Center align the content */
    background: rgba(217, 217, 217, 0.105);
    border-radius: 102px;

    .title-flex {
      display: flex;
      align-items: center;
      justify-content: center; /* Center align the flex items */
      gap: 1em; /* Adjust the gap as needed */
      margin-bottom: 40px;
      text-align: center;

      .pterodactyl-img,
      .fossil-img {
        width: 5em; /* Adjust the size as needed */
        height: auto;
        animation: rotate-slightly 3s infinite alternate ease-in-out; /* Add animation */
      }

      .major {
        font-size: 3em;
        font-weight: 700;
        font-family: "Changa", sans-serif;
        line-height: 0.9em;
      }
    }

    .major {
      display: block;
      position: relative;
      height: fit-content;
      width: 100%;
      text-align: center;
      font-size: 3em;
      margin-bottom: 40px;
      font-weight: 700;
      font-family: "Changa", sans-serif;
      line-height: 0.9em;
    }

    .mini {
      display: block;
      position: relative;
      height: fit-content;
      width: 100%;
      text-align: center; /* Center align the text */
      font-size: 1.3em;
      font-weight: 400;
      font-family: "Changa", sans-serif;
      line-height: 1.3em;
    }

    .star {
      display: block;
      position: absolute;
      width: 3em;
      filter: drop-shadow(0px 0px 50px #f3ae4a);
    }

    .star-a {
      width: 5.5em;
      top: 0;
      left: -2em;
    }

    .star-b {
      width: 2.7em;
      top: 3.5em;
      left: 3em;
    }

    .star-c {
      width: 2.7em;
      top: 6em;
      left: 1em;
    }

    .star-d {
      width: 6em;
      bottom: -0.5em;
      right: -0.5em;
    }

    .star-e {
      width: 2.7em;
      bottom: -1.2em;
      right: 4.3em;
    }

    .star-f {
      width: 2.5em;
      bottom: -2.5em;
      right: -1em;
    }
  }
}

#whatis #whatis2 {
  margin: 100px 0;

  .whatis-flex {
    display: flex;
    position: relative;
    width: 80%;
    padding: 100px;
    margin: 0 auto;
    height: fit-content;
    color: #000;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; /* Center align the content */
    background: rgba(217, 217, 217, 0.105);
    border-radius: 102px;

    .title-flex {
      display: flex;
      align-items: center;
      justify-content: center; /* Center align the flex items */
      gap: 1em; /* Adjust the gap as needed */
      margin-bottom: 40px;
      text-align: center;

      .pterodactyl-img,
      .fossil-img {
        width: 5em; /* Adjust the size as needed */
        height: auto;
        animation: rotate-slightly 3s infinite alternate ease-in-out; /* Add animation */
      }

      .major {
        font-size: 3em;
        font-weight: 700;
        font-family: "Changa", sans-serif;
        line-height: 0.9em;
      }
    }

    .major {
      display: block;
      position: relative;
      height: fit-content;
      width: 100%;
      text-align: center;
      font-size: 3em;
      margin-bottom: 40px;
      font-weight: 700;
      font-family: "Changa", sans-serif;
      line-height: 0.9em;
    }

    .mini {
      display: block;
      position: relative;
      height: fit-content;
      width: 100%;
      text-align: center; /* Center align the text */
      font-size: 1.3em;
      font-weight: 400;
      font-family: "Changa", sans-serif;
      line-height: 1.3em;
    }

    .star {
      display: block;
      position: absolute;
      width: 3em;
      filter: drop-shadow(0px 0px 50px #f3ae4a);
    }

    .star-a {
      width: 5.5em;
      top: 0;
      left: -2em;
    }

    .star-b {
      width: 2.7em;
      top: 3.5em;
      left: 3em;
    }

    .star-c {
      width: 2.7em;
      top: 6em;
      left: 1em;
    }

    .star-d {
      width: 6em;
      bottom: -0.5em;
      right: -0.5em;
    }

    .star-e {
      width: 2.7em;
      bottom: -1.2em;
      right: 4.3em;
    }

    .star-f {
      width: 2.5em;
      bottom: -2.5em;
      right: -1em;
    }
  }
}

#whenwhere {
  position: relative; /* Ensure the section is the relative container */
  padding: 0; /* Remove any padding from the container */
  margin: 0; /* Remove any margin from the container */
  overflow: hidden; /* Ensure content doesn't overflow */

  .whenwhere-flex {
    display: flex;
    position: relative;
    width: 80%;
    padding: 0px;
    margin: 0 auto;
    height: fit-content;
    color: #000;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .major {
      display: block;
      position: relative;
      height: fit-content;
      width: 100%;
      text-align: center;
      font-size: 3em;
      margin-bottom: 40px;
      font-weight: 700;
      font-family: "Changa", sans-serif;
      line-height: 0.9em;
    }

    .split-flex {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      gap: 30px;

      .info-column {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 50%;
        text-align: left;

        .when-where-header {
          font-size: 2.5em;
          font-weight: 500;
          font-family: "Changa", sans-serif;
          color: rgba(0, 0, 0, 0.8);
          margin: 0 0 10px 0;
        }

        .mini {
          font-size: 1.5em;
          font-weight: 500;
          font-family: "Changa", sans-serif;
          color: rgba(0, 0, 0, 0.8);
          margin: 0;
        }

        a {
          color: inherit;
          text-decoration: underline;
        }
      }

      .map {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 100%;

        iframe {
          width: 300px; /* Fixed width */
          height: 300px; /* Fixed height */
          border-radius: 20px; /* Rounded corners */
        }
        margin-top: 3em;
        border: 3px solid black; /* Black border */
        border-radius: 20px; /* Rounded corners */
        width: 300px; /* Fixed width */
        height: 300px; /* Fixed height */
      }
    }
  }

  .vines-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 35vw; /* Use viewport width units to make it responsive */
    height: auto;
    z-index: 0; /* Ensure the image is on top */
    margin: 0; /* Remove any margin from the image */
    margin-left: -10em;
  }

  .dbones {
    position: absolute;
    top: 5em;
    left: 0;
    width: 20%; /* Use viewport width units to make it responsive */
    height: auto;
    z-index: 0; /* Ensure the image is on top */
    margin: 0; /* Remove any margin from the image */
  }

  .car-img {
    position: absolute;
    bottom: 5em; /* Adjust to move the car closer to the map */
    right: -1em; /* Adjust to move the car closer to the map */
    width: 20vw; /* Adjust width as needed */
    height: auto;
    z-index: 0; /* Ensure the image is on top */
    animation: drive 10s infinite ease-in-out;
  }

  @keyframes drive {
    0% {
      transform: translateX(0);
    }
    30% {
      transform: translateX(
        -100vw
      ); /* Move the car off the left side of the screen */
    }
    60% {
      transform: translateX(-100vw); /* Keep the car off-screen to the left */
    }
    70% {
      transform: translateX(
        100vw
      ); /* Move the car to the right side off-screen */
      opacity: 0;
    }
    100% {
      transform: translateX(0); /* Move the car back to the original position */
    }
  }
}

#faq {
  display: flex;
  justify-content: center;
  align-items: center;

  .faq-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1200px; /* Adjust as needed */
    margin: 0 auto;

    > .major {
      font-size: 4em;
      font-family: "Changa", sans-serif;
      text-align: center;
      width: 100%;
    }

    > .faq-grid {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1.5em 3em;
      justify-content: center;
      width: 100%;
      margin-right: 2.2em;

      > .faq-box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45%; /* Adjust as needed */
        height: 6em; /* Set a fixed height to accommodate two lines of text */
        text-align: center;
        padding: 1em;
        box-sizing: border-box;
        background: #fff;
        border-radius: 0.5em;
        box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.1);
        font-size: 1.2em;
        line-height: 1.5em;
      }
    }
  }
}

#speakers {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  .speakers-flex {
    display: flex;
    position: relative;
    height: fit-content;
    width: 80%;

    flex-direction: column;
    margin: 0 auto;

    gap: 1em;

    > .major {
      display: block;
      position: relative;
      height: fit-content;
      width: 100%;

      text-align: left;
      font-size: 5em;
      font-weight: 700;
      line-height: 1.3em;
      font-family: "crimson-pro";
      font-style: italic;
    }

    > .tri-flex {
      display: flex;
      position: relative;
      width: 100%;
      min-height: 30vh;

      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;

      gap: 2em;

      > .speaker-card {
        display: block;
        position: relative;
        width: 40%;
        min-width: 200px;
        max-width: calc(100% - 6em);
        height: fit-content;
        padding-top: 0;

        justify-content: center;

        > .speaker-bg {
          display: block;
          position: absolute;
          width: 100%;
          height: calc(100% - 70px);
          bottom: 0;
          left: 0;
          z-index: -1;

          background: rgba(217, 217, 217, 0.205);
          border-radius: 3em;
        }

        > span {
          width: 100%;
          height: 100%;
          > img {
            display: block;
            position: relative;
            width: 12em;
            height: 12em;
            object-fit: cover;
            margin: 0 auto;
            border-radius: 100%;
          }
        }

        > .speaker-major {
          display: block;
          position: relative;
          height: fit-content;
          width: 100%;

          text-align: center;
          font-size: 1.6em;
          font-family: "crimson-pro";
          font-weight: 700;
          line-height: 1.3em;
          font-style: italic;

          > a {
            text-decoration: none;
            cursor: pointer;

            &:hover {
              color: rgb(0, 0, 0);
              text-decoration: underline;
            }
          }
        }

        > .speaker-mini {
          display: inline-block;
          position: relative;
          height: fit-content;
          width: 80%;
          margin: 0 auto;

          justify-content: center;
          text-align: left;
          font-size: 1em;
          font-weight: 300;
          line-height: 1.2em;
          padding-bottom: 2em;
        }
      }
    }
  }

  .tba {
    display: flex;
    position: absolute;
    width: 100%;
    height: 70%;
    align-items: center;

    backdrop-filter: blur(5px); // Adjust the blur radius as needed
    bottom: 0%;
    > .major {
      margin: 0;
      text-align: center;
      font-size: 3em;
    }
  }
}
#team {
  .team-flex {
    > .major {
      font-size: 4em;
    }

    > .team-grid {
      > .team-member-card {
        width: 40%;
        height: 10em;

        > .img-container {
          span {
            img {
              width: 6em;
              height: 6em;
            }
          }
        }

        > .nametitle {
          a {
            > .name {
              font-size: 1.4em;
            }
          }

          > .title {
            font-size: 0.9em;
            line-height: 1.4em;
          }
        }
      }
      gap: 0 2em;
    }
  }
}

#schedule {
  color: #000;
  padding: 100px 0;
  min-height: 70vh;
  .schedule-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #000;
  }
  .major {
    display: block;
    position: relative;
    height: fit-content;
    width: 100%;
    text-align: center;
    font-size: 3em;
    margin-bottom: 40px;
    font-weight: 700;
    font-family: "Changa";
    line-height: 0.9em;
  }
}

#tracks {
  .tracks-flex {
    display: flex;
    position: relative;
    width: 80%;
    padding: 100px 0;
    margin: 0 auto;
    height: fit-content;
    margin: 0 auto;

    color: #000;

    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;

    gap: 1em;

    .clicktoflip {
      font-size: 0.4em;
      font-weight: 400;
      letter-spacing: 0.5px;
    }

    > .major {
      display: block;
      position: relative;
      height: fit-content;
      width: 100%;
      text-align: center;
      font-size: 3em;
      margin-bottom: 40px;
      font-weight: 700;
      font-family: "Changa", sans-serif; /* Use Changa font */
      line-height: 0.9em;
    }
  }
}

#challenges {
  .tracks-flex {
    display: flex;
    position: relative;
    width: 80%;
    padding: 100px 0;
    margin: 0 auto;
    height: fit-content;
    margin: 0 auto;

    color: #000;

    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;

    gap: 1em;

    .clicktoflip {
      font-size: 0.4em;
      font-weight: 400;
      letter-spacing: 0.5px;
    }

    > .major {
      display: block;
      position: relative;
      height: fit-content;
      width: 100%;
      text-align: center;
      font-size: 3em;
      margin-bottom: 40px;
      font-weight: 700;
      font-family: "Changa", sans-serif; /* Use Changa font */
      line-height: 0.9em;
    }
  }
}

#prizes {
  color: #000;

  .major {
    display: block;
    position: relative;
    height: fit-content;
    width: 100%;
    text-align: center;
    font-size: 3em;
    margin-bottom: 40px;
    font-weight: 700;
    font-family: "Changa", sans-serif;
    line-height: 0.9em;
  }

  .prizes-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 80%;
    padding: 100px 0;
    margin: 0 auto;
    color: #000;

    .footprint-trail {
      width: 100%; /* Adjust the width as needed */
      height: auto;
      margin-left: 11vw;
      margin-top: 10%;
      padding: 20px 20px 20px 20px;
    }

    .prize-image {
      position: absolute;
      width: 150px; /* Increase the size */
    }

    .first-place {
      top: 22%; /* Adjust the top position as needed */
      left: 50%;
      width: 20%; /* Adjust the width as needed */
      height: auto;
      transform: translateX(-50%);
      margin-top: 6vh;
    }

    .second-place {
      top: 65%; /* Adjust the top position as needed */
      left: 7%; /* Adjust the left position as needed */
      width: 20%; /* Adjust the width as needed */
      height: auto;
    }

    .third-place {
      top: 65%; /* Adjust the top position as needed */
      left: 71%; /* Adjust the left position as needed */
      width: 20%; /* Adjust the width as needed */
      height: auto;
    }

    .prize-details {
      position: absolute;
      width: 20vw; /* Adjust the width as needed */
      text-align: center;
      font-family: "Changa", sans-serif;
    }

    .first-place-details {
      top: 45%; /* Adjust the top position to create sufficient distance */
      left: 50%;
      transform: translateX(-50%);
    }

    .second-place-details {
      top: 78%; /* Adjust the top position to create sufficient distance */
      left: 2%; /* Adjust the left position as needed */
    }

    .third-place-details {
      top: 78%; /* Adjust the top position to create sufficient distance */
      left: 66%; /* Adjust the left position as needed */
    }

    @keyframes rotate-slightly {
      0%,
      100% {
        transform: rotate(0deg);
      }
      50% {
        transform: rotate(10deg);
      }
    }

    .dinosaur-fossil {
      position: absolute;
      top: 0;
      right: 0;
      width: 35%; /* Adjust the size as needed */
      height: auto;
      animation: rotate-slightly 3s infinite alternate ease-in-out; /* Add animation */
    }
  }
}

#speakers {
  .speakers-flex {
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // width: 96%;
    // padding: 5vh 0;
    // margin: 0 auto;
    color: #000;

    .major {
      font-size: 3em;
      margin-bottom: 4vh;
      font-weight: 700;
      font-family: "Changa", sans-serif;
      text-align: center;
    }

    .speaker-grid {
      display: flex;
      flex-wrap: wrap;
      gap: 2vw;
      justify-content: center;

      .speaker-card {
        width: 45%; /* Two cards per row */
        margin-bottom: 4vh;
      }
    }

    .pterodactyl {
      position: absolute;
      top: 10%;
      left: 5%;
      width: 40%;
      height: auto;
      animation: rotate-slightly 3s infinite alternate ease-in-out; /* Add animation */
      margin-left: 40em;
    }
  }
}

#sponsors {
  padding: 3em 0;
  background-color: rgb(219, 232, 196);

  .invert-colors {
    filter: invert(100%);
  }

  .pastsponsors-flex {
    display: flex;
    position: relative;
    width: 80%;
    height: fit-content;
    margin: 0 auto;

    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    .wood-sponsor-banner {
      display: flex;
      justify-content: center; /* Center horizontally */
      align-items: center; /* Center vertically */
      position: relative;
      width: 100%; /* Ensure it takes the full width */
      height: fit-content;
    }

    .sponsor-title {
      position: absolute;
      font-size: 3em;
      font-weight: 700;
      font-family: "Changa", sans-serif;
      color: #000;
      bottom: 2.35em;
    }

    .partner-title {
      position: absolute;
      font-size: 2.5em;
      font-weight: 700;
      font-family: "Changa", sans-serif;
      color: #000;
      bottom: 3.2em;
    }

    .banner-sponsor-img {
      width: 100%;
      max-width: 600px;
    }

    > .left {
      justify-content: flex-start !important;
    }

    > .major {
      display: block;
      position: relative;
      width: 100%;
      height: fit-content;

      color: #000;

      font-size: 5em;
      font-weight: bold;
      text-align: center;
      line-height: 1.3em;
      font-family: "Changa", sans-serif;
      font-style: italic;
    }

    > .sponsors-flex {
      display: flex;
      position: relative;
      height: fit-content;
      width: 100%;

      justify-content: space-evenly;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1em;

      > .sponsor {
        display: block;
        position: relative;
        height: 12em;
        width: 12em;
        padding: 0.5em;

        > span {
          display: flex !important;
          position: relative;
          width: 100%;
          height: 100%;

          flex-direction: column;
          justify-content: center;
          align-items: center;

          > img {
            display: block;
            position: relative;
            max-width: 100%;
            max-height: 100%;
          }
        }
      }

      > .sponsor-large {
        display: block;
        position: relative;
        height: 12em;
        width: 16em;
        padding: 0.5em;

        > span {
          display: flex !important;
          position: relative;
          width: 100%;
          height: 100%;

          flex-direction: column;
          justify-content: center;
          align-items: center;

          > img {
            display: block;
            position: relative;
            width: 16em;
            max-width: 100%;
            max-height: 100%;
          }
        }
      }

      > .sponsor-larger {
        display: block;
        position: relative;
        height: 12em;
        width: 20em;
        padding: 0.5em;

        > span {
          display: flex !important;
          position: relative;
          width: 100%;
          height: 100%;

          flex-direction: column;
          justify-content: center;
          align-items: center;

          > img {
            display: block;
            position: relative;
            width: 20em;
          }
        }
      }

      > .sponsor-largerer {
        display: block;
        position: relative;
        height: 12em;
        width: 24em;
        padding: 0.5em;

        > span {
          display: flex !important;
          position: relative;
          width: 100%;
          height: 100%;

          flex-direction: column;
          justify-content: center;
          align-items: center;

          > img {
            display: block;
            position: relative;
            width: 24em;
          }
        }
      }

      > .sponsor-largest {
        display: block;
        position: relative;
        height: 12em;
        width: 45em;
        padding: 0.5em;

        > span {
          display: flex !important;
          position: relative;
          width: 100%;
          height: 100%;

          flex-direction: column;
          justify-content: center;
          align-items: center;

          > img {
            display: block;
            position: relative;
            width: 45em;
          }
        }
      }

      > .sponsor-small {
        display: block;
        position: relative;
        height: 12em;
        width: 10em;
        padding: 0.5em;

        > span {
          display: flex !important;
          position: relative;
          width: 100%;
          height: 100%;

          flex-direction: column;
          justify-content: center;
          align-items: center;

          > img {
            display: block;
            position: relative;
            width: 10em;
          }
        }
      }

      > .sponsor-smaller {
        display: block;
        position: relative;
        height: 12em;
        width: 8em;
        padding: 0.5em;

        > span {
          display: flex !important;
          position: relative;
          width: 100%;
          height: 100%;

          flex-direction: column;
          justify-content: center;
          align-items: center;

          > img {
            display: block;
            position: relative;
            width: 8em;
          }
        }
      }
    }
  }

  .minor {
    display: block;
    position: relative;
    width: 100%;
    height: fit-content;

    color: #000;

    font-size: 4em;
    font-weight: bold;
    text-align: left;
    line-height: 1.3em;
    font-family: "Changa", sans-serif; /* Apply Changa font */
    font-style: italic;
  }
}

@media only screen and (max-width: 1400px) {
  .home-page {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1300px) {
  .home-page {
    font-size: 13px;
  }
}

@media only screen and (max-width: 1200px) {
  .mlh-section > .mlh-wrapper {
    justify-content: flex-start;
    & > .mlh-badge {
      width: 60px;
      margin: 0 10px;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .home-page {
    .travel-reimbursement-banner {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      background-color: orange;
      padding: 8px 0; /* Reducido de 15px a 8px */
      z-index: 1000;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      font-weight: 700;
    }

    .banner-text {
      margin: 0;
      text-align: center;
      font-size: 1em; /* Reducido de 1.2em a 1em */
      color: black;
      font-weight: bold;
    }
  }

  .continue-reading {
    display: none;
  }

  #team {
    .team-flex {
      > .team-grid {
        gap: 1em 2em;
        > .team-member-card {
          gap: 1em;
          > .nametitle {
            a {
              > .name {
                font-size: 1.4em;
              }
            }

            > .title {
              font-size: 0.9em;
            }
          }
        }
      }
    }
  }
}

.continue-reading-hero {
  display: none;
  animation: hoverhero 5s infinite;
}

.mlh-section {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 5em;
  width: 100%;
  margin: 0 auto;
  padding-top: 4em;

  z-index: 6;
  pointer-events: none;

  > .mlh-wrapper {
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;

    > .mlh-badge {
      display: block;
      position: relative;
      width: 100px;
      height: auto;
      pointer-events: auto;

      > span {
        width: 100%;
        height: auto;

        > img {
          display: block;
          position: relative;
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

//mobile
@media only screen and (max-width: 1400px) {
  .home-page {
    font-size: 16px;
  }
}
@media only screen and (max-width: 1200px) {
  // .mlh-section {
  //     display: none;
  // }
  .mlh-section > .mlh-wrapper {
    justify-content: flex-start;
    & > .mlh-badge {
      width: 60px;
      margin: 0 10px;
    }
  }

  #prizes {
    .prizes-flex {
      .prize-grid {
        > .prize-card {
          order: 2;
        }
        > .winner {
          order: -1;
        }
        > .card-down {
          // dont push down
          margin-top: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .home-page {
    font-size: 14px;
  }
  #hero {
    .hero-flex {
      flex-direction: column;
      align-items: center;
      padding: 0 20px;

      > .flex-left,
      > .flex-right {
        width: 100%;
        align-items: center;
      }

      > .flex-right {
        display: none; /* Hide the logo and stars on mobile */
      }

      > .flex-left {
        > .hero-major,
        > .hero-minor,
        > .hero-presented,
        > .hero-date,
        > .hero-date-mini,
        > .hero-button {
          text-align: center;
          margin-left: 0; /* Remove left margin */
        }

        > .hero-button {
          margin-top: 1em;
          margin-bottom: 1em;
        }

        > .hero-date {
          text-align: center;
          margin-left: 0;
        }
      }
    }
  }

  #whatis,
  #whatis2 {
    .whatis-flex {
      width: 90%;
      padding: 100px 40px;
    }
  }

  #whenwhere {
    .whenwhere-flex {
      padding: 100px 0;
    }
  }

  #sponsors {
    .pastsponsors-flex {
      > .sponsors-flex {
        > .sponsor-row {
          > .sponsor {
            padding: 5px;
          }
        }
        > .sponsor-row-a {
          height: 80px;
        }
        > .sponsor-row-b {
          height: 60px;
        }
        > .sponsor-row-c {
          height: 50px;
        }
      }
    }
  }

  #speakers {
    .speakers-flex {
      .speaker-card {
        font-size: 1.6em;
      }
    }
  }

  #prizes {
    .prizes-flex {
      .prize-grid {
        > .prize-card {
          font-size: 1.6em;
        }
      }
    }
  }

  #footer {
    font-size: 12px;
  }
}

//mobile
@media only screen and (max-width: 600px) {
  .home-page {
    font-size: 10px;
  }

  #whenwhere {
    .whenwhere-flex {
      > .split-flex {
        flex-direction: column;

        > .mini {
          width: 100%;
        }
        > .map {
          width: 100%;
          height: 100%;
          margin-top: 10px;
        }
      }
    }
  }

  .continue-reading {
    display: none;
  }
  .continue-reading-hero {
    display: flex;
  }

  #sponsors {
    .pastsponsors-flex {
      .sponsors-flex {
        > .sponsor-long {
          width: 70%;
        }
        > .sponsor {
          width: 30em;
          height: 15em;
        }
      }
    }
  }
}
